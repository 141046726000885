import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = ({ data }) => (
  <Layout activeLink='projects'>
    <SEO title="Projects"/>
    <h2>Projects</h2>
    <div className="row">
      {data.allSanityProject.nodes.map(Project)}
    </div>
  </Layout>
);

const Project = ({ name, icon_url, description }) => (
  <div className="col-sm-6 col-md-6 col-lg-4 my-3">
    <div className="card h-100 bg-dark">
      <img alt={`${name} Icon`} className="card-img-top" src={icon_url}/>
      <div className="card-body">
        <h5 className="card-title">{name}</h5>
        <p className="card-text">{description}</p>
    </div>
    </div>
  </div>
);

export const query = graphql`
    query {
        allSanityProject {
            nodes {
                name
                description
                icon_url
            }
        }
    }
`;


export default IndexPage;
